import { useMemo } from 'react';

import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';

import { theme as blackTheme } from './theme-black';
import { theme as defaultTheme } from './theme-default';

export const useGetTheme = () => {
  const { theme } = useQlubRouter();
  const qlubTheme = useMemo(() => {
    switch (theme) {
      case 'black':
        return blackTheme;
      default:
        return defaultTheme;
    }
  }, [theme]);
  return { theme: qlubTheme };
};
